<template>
  <div class="my-second juan">
      <Head :title="$t('mine.el_card')" :show="true" />
      <div class="common-box juan-box" v-if="list.length>0">
          <MyList ref="mylist" :listadd="listadd">
            <div  class="juan-box-item "
            v-for="(l,i) in list " :key="i">
                <img class="tick-bg" v-lazy="l.tickinfo.bg_img" alt="" >
                <div class="tick-main">
                    <div class="juan-box-item-left">
                      <img v-lazy="l.tickinfo.image" alt="">
                      <!-- <img :src="i%2==0?require('@/assets/images/tou-coffee.png'):require('@/assets/images/tou-mai.png')" alt=""> -->
                      <div class="juan-info">
                        <p class="juan-value">{{$t('home.face_value')}}:${{l.tickinfo.face_value | number_format}}</p>
                        <p class="juan-name">{{l.tickinfo.name}}</p>
                        <p class="juan-time">{{$t('info.data_before')}}:{{l.tickinfo.expired_at}}</p>
                      </div>
                  </div>
                  <div class="juan-box-item-right">
                      <van-button v-if="l.tickinfo.type_id==0"  class="btn-use" @click="gotousejuan(l)">{{$t('mine.to_use')}}</van-button>
                      <template v-else>
                           <van-button class="btn-use" v-if="l.tickCodeinfo_is_used==0" @click="gotousejuan(l)">{{$t('mine.to_use')}}</van-button>
                           <van-button class="btn-use has-use" v-if="l.tickCodeinfo_is_used==1" >{{$t('mine.has_use')}}</van-button>
                      </template>
                    
                      <!-- <van-button class="btn-use has-use"  disabled >{{$t('mine.has_use')}}</van-button> -->
                  </div>
                </div>
            </div>
          </MyList>  
      </div>
      <div class="common-box juan-box" v-else>
        <div class="no-box">
            <img class="juan-no" src="../../assets/images/juan-no.png" alt="">
            <p class="no-text">{{$t('mine.no_price_card')}}</p>
        </div>
      </div>

        <van-overlay class="g-overlay" :show="show"  @click="show=false">
          <div class="touse-mask" @click.stop>
             <van-field
              :label="$t('wallet.card_num')"
              readonly
              clickable
              v-model="item.tickCodeinfo_code"
              >
              <template #button>
                <van-button class="copy-btn" @click="onCopy('num')">{{$t('mine.copy')}}</van-button>  
              </template>
              </van-field>
                <van-field
                  :label="$t('add.card_pass')"
                  readonly
                  clickable
                  v-model="item.tickCodeinfo_codepwd"
                  class="cellcenter"
              >
                  <template #button>
                      <van-button class="copy-btn" @click="onCopy('pass')">{{$t('mine.copy')}}</van-button>  
                  </template>
              </van-field>
          </div>
        </van-overlay>

              <!-- 支付 输入二级密码   -->
     <van-popup class="login-popup pay-one money-pay" v-model="secshow" position="bottom"   duration="0.2" >
        <div class="login-popup-box">
            <div class="pop-top">
                <p>{{$t('login.confirm')}}{{$t('wallet.pay')}}</p>
                <van-icon name="cross" class="pop-close" @click="secshow=false" />
            </div>
            <van-form label-width="140" class="pay-form" ref="pinform" @submit="gotopayend()">
                
                <van-field
                v-model="tickpin"
                :label="$t('login.second_pass')"
                :placeholder="$t('login.required_sec_pass')"
                :type="pt3"
                name="tickpin"
                :right-icon="pt3=='password'?'eye':'closed-eye'"
                @click-right-icon="changePassType3"
                :rules="[
                  { required: true, message: $t('login.required_sec_pass') },
                  { pattern:passPattern,message:$t('login.secpass_error'),trigger:'onBlur'}
                ]"
                >

                </van-field>
            </van-form>
            <div class="last-btns">
                <van-button class="the-btn btn-one" @click="secshow=false">{{$t('login.cancel')}}</van-button>
                <van-button class="the-btn btn-two" @click="launchForm">{{$t('login.confirm')}}</van-button>
            </div>
        </div>
    </van-popup>

  </div>
</template>

<script>
import {Toast} from "vant"
import {passReg} from "@/common/validate"
import {randomString} from "@/common"
export default {
  name:"juan",
  data(){
    return {
      list:[],
      page:1,
      pageSize:20,
      total:0,
      allList:[],
      show:false,
      item:{},
      secshow:false,
      tickpin:'',
      pt3:"password",
      passPattern:passReg,
    }
  },
  methods:{
    launchForm(){
         this.$refs.pinform.submit();
    },
    gotopayend(){
        this.$ajax.exchangeCashticket({
            pin:this.tickpin,
            tick_id:this.item.orderid,
            form_token:randomString(),
        }).then(res=>{
             if(res.code==200){
                  this.secshow = false;
                  this.list = this.list.map(item=>{
                    if(item.orderid==this.item.orderid){
                       item = {...item,tickCodeinfo_is_used:1}  // 已使用 
                    }
                    return item;
                  })
             }
        })
    },
    changePassType3(){
          this.pt3 = this.pt3 == "password"?"text":"password"
    },

    onCopy(text) {
        var flag = this.copyText(text=="pass"?this.item.tickCodeinfo_codepwd:this.item.tickCodeinfo_code);
        Toast.success(this.$t('news.copysuccess'))
    },
    copyText(shareLink) {
          var _input = document.createElement("input");   // 直接构建input
          _input.value = shareLink;  // 设置内容
          document.body.appendChild(_input);    // 添加临时实例
          _input.select();   // 选择实例内容
          document.execCommand("Copy");   // 执行复制
          document.body.removeChild(_input); // 删除临时实例
      },
    gotousejuan(item){
      
      this.item = item;
      if(item.tickinfo.type_id=="0"){   //   普通卡卷 
          this.show = true; 
      }else if(item.tickinfo.type_id=="1"){  // 现金卡卷  
          this.secshow = true;
      }
    },
    listadd(cb1,cb2){
      if(this.page<Math.ceil(this.total/this.pageSize)){
        this.page++;
        this.$ajax.buyticketList({
          page:this.page,
          pageSize:this.pageSize
        })
        .then(res=>{
          if(res.code==200){
            this.list = this.list.concat(res.data.data);
            this.total = res.data.total;
          }
          cb1();
        })
      }else{
        cb2()
      }
    }
  },
  mounted(){
    this.$ajax.buyticketList({
      page:this.page,
      pageSize:this.pageSize
    })
    .then(res=>{
      if(res.code==200){
        this.list = res.data.data;
        this.total = res.data.total;
      }
    })
  }
}
</script>